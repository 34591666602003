.reviews {
  position: relative;

  #reviews {
    position: absolute;
    top: -66px;
  }

  &__content {
    padding-top: 47px;
    padding-bottom: 56px;
  }

  &__title {
    margin-bottom: 24px;

    font-size: 30px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
  }

  &__slider {
    position: relative;

    margin-bottom: 28px;
  }

  &__slide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 38px 26px 17px;

    border: 1px solid #f9e5c1;
    border-radius: 5px;

    &.cut {
      .reviews__text {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .reviews__button {
        visibility: visible;
      }
    }
  }

  &__name {
    margin-bottom: 11px;

    font-size: 22px;
    font-weight: 500;
    line-height: 1.2;
  }

  &__grade {
    margin-bottom: 18px;

    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;

    color: map-get($colors, 'second');
  }

  &__text {
    min-height: 125px;

    font-size: 16px;
    line-height: 1.5719;
  }

  &__button {
    margin-top: 37px;
    visibility: hidden;

    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;

    color: map-get($colors, 'second');
  }

  &__prev {
    position: absolute;
    top: 50%;
    left: -20px;

    width: 20px;
    height: 40px;

    transform: translateY(-50%);

    &::before {
      position: absolute;
      top: 15px;
      left: 3px;

      width: 10px;
      height: 10px;

      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      border-bottom-left-radius: 1px;
      transform: rotate(45deg);

      content: '';
    }

    &::after {
      position: absolute;
      top: 19px;
      left: 3px;

      width: 15px;
      height: 2px;

      background-color: #000;

      content: '';
    }
  }

  &__next {
    position: absolute;
    top: 50%;
    right: -20px;

    width: 20px;
    height: 40px;

    transform: translateY(-50%);

    &::before {
      position: absolute;
      top: 15px;
      right: 3px;

      width: 10px;
      height: 10px;

      border-top: 2px solid #000;
      border-right: 2px solid #000;
      border-top-right-radius: 1px;
      transform: rotate(45deg);

      content: '';
    }

    &::after {
      position: absolute;
      top: 19px;
      right: 3px;

      width: 15px;
      height: 2px;

      background-color: #000;

      content: '';
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;

      border: 1px solid map-get($colors, 'main');
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 12px;
      }

      &-active {
        width: 14px;
        height: 14px;

        background-color: map-get($colors, 'main');
      }
    }
  }

  &__add {
    display: block;
    height: 50px;
    margin: 0 auto;
    padding: 0 36px;

    border: 1px solid map-get($colors, 'main');
    border-radius: 5px;

    &:active,
    &:hover {
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }
}

@media (min-width: 768px) {
  .reviews {
    #reviews {
      top: -102px;
    }

    &__content {
      padding-top: 75px;
      padding-bottom: 75px;
    }

    &__title {
      margin-bottom: 26px;

      font-size: 40px;
      text-align: left;
    }

    &__slide {
      padding-left: 22px;
      padding-right: 20px;
      padding-bottom: 35px;
    }

    &__name {
      margin-bottom: 6px;

      font-size: 26px;
    }
  }
}

@media (min-width: 1200px) {
  .reviews {
    #reviews {
      top: -84px;
    }

    &__content {
      position: relative;

      padding-top: 85px;
      padding-bottom: 99px;
    }

    &__title {
      margin-bottom: 37px;
    }

    &__prev {
      left: -60px;

      width: 40px;

      &::before {
        left: 1px;
      }

      &::after {
        left: 1px;

        width: 38px;
      }
    }

    &__next {
      right: -60px;

      width: 40px;

      &::before {
        right: 1px;
      }

      &::after {
        right: 1px;

        width: 38px;
      }
    }

    &__pagination {
      margin-bottom: 0;
    }

    &__add {
      position: absolute;
      top: 85px;
      right: 0;
    }
  }
}
