.services {
  $p: &;

  position: relative;

  #services {
    position: absolute;
    top: -66px;
  }

  &__content {
    padding-top: 50px;
  }

  &__title {
    margin-bottom: 55px;

    font-size: 30px;
    font-weight: 700;
    line-height: 1.1719;
    text-align: center;
  }

  &__list {
    margin-bottom: 50px;
  }

  &__item {
    display: none;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 60px;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      display: flex;
    }
  }

  &__image {
    max-width: 50px;
    margin-bottom: 16px;
  }

  &__name {
    max-width: 220px;

    font-size: 16px;
    font-weight: 500;
    line-height: 1.36;
    text-align: center;

    strong {
      font-weight: 400;

      color: map-get($colors, 'second');
    }
  }

  &__more {
    position: relative;

    display: block;
    margin: 0 auto;
    padding-right: 17px;

    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;

    color: map-get($colors, 'second');

    &::before {
      position: absolute;
      top: 3px;
      right: 2px;

      width: 8px;
      height: 8px;

      border-left: 1px solid map-get($colors, 'second');
      border-bottom: 1px solid map-get($colors, 'second');
      transform: rotate(-45deg);

      content: '';
    }
  }

  &--active {
    #{$p}__item {
      display: flex;
    }

    #{$p}__more {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  .services {
    #services {
      top: -102px;
    }

    &__content {
      padding-top: 63px;
    }

    &__title {
      margin-bottom: 45px;

      font-size: 40px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
    }

    &__item {
      display: flex;
      width: calc(33.33333% - 30px);
      margin: 0 15px;
      margin-bottom: 60px;
    }

    &__more {
      display: none;
    }
  }
}

@media (min-width: 1200px) {
  .services {
    #services {
      top: -84px;
    }

    &__content {
      padding-top: 100px;
    }

    &__title {
      margin-bottom: 35px;
    }

    &__item {
      width: calc(25% - 30px);
      margin-bottom: 40px;

      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
}
