.module {
  position: absolute;

  border: 1px solid #efe1cd;
  border-radius: 5px;
  background-color: #fff;

  :global {
    .DayPicker-NavButton--prev {
      left: 1.5rem;
      right: auto;
    }

    .DayPicker-Caption {
      & > div {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
      }
    }

    .DayPicker-Weekday {
      font-size: 14px;

      color: rgba(126, 126, 126, 0.58);
    }

    .DayPicker-Day {
      font-size: 16px;

      color: rgba(0, 0, 0, 0.8);

      &--disabled {
        color: #e7e7e7;
      }

      &--today {
        color: #694a38;
      }

      &--selected:not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
        border-radius: 0;
        color: rgba(0, 0, 0, 0.8);
        background-color: rgba(255, 6, 132, 0.12);

        & + .DayPicker-Day--end {
          position: relative;

          &::before {
            position: absolute;
            top: 0;
            left: 0;

            width: 5px;
            height: 100%;

            background-color: rgba(255, 6, 132, 0.12);

            content: '';
          }
        }
      }

      &--start:not(.DayPicker-Day--outside) {
        border-radius: 5px;
        color: #fff;
        background-image: linear-gradient(
          89.63deg,
          #ff0684 4.39%,
          #ff3939 87.14%
        );
        background-color: transparent;

        & + .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
          position: relative;

          &::before {
            position: absolute;
            top: 0;
            right: 100%;

            width: 5px;
            height: 100%;

            background-color: rgba(255, 6, 132, 0.12);

            content: '';
          }
        }
      }

      &--end:not(.DayPicker-Day--outside) {
        border-radius: 5px;
        color: #fff;
        background-image: linear-gradient(
          89.63deg,
          #ff0684 4.39%,
          #ff3939 87.14%
        );
        background-color: transparent;
      }
    }

    .DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background-color: #faf8f4;
    }
  }
}
