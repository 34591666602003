.module {
  position: relative;

  padding: 49px 20px 57px;
}

.title {
  margin-bottom: 49px;

  font-size: 26px;
  font-weight: 500;
  line-height: 1.1719;
  text-align: center;
}

.grade {
  margin-bottom: 50px;
  padding: 0 10px;
}

.gradeTitle {
  margin-bottom: 20px;

  font-size: 16px;
  line-height: 1.1719;

  color: #999;
}

.gradeSlider {
  height: 6px;
  padding: 0;
  background-color: pink;

  :global {
    .rc-slider-rail {
      height: 6px;

      background-color: #f1f1f1;
    }

    .rc-slider-track {
      height: 6px;

      background-color: #694a38;
    }

    .rc-slider-step {
      display: none;
    }

    .rc-slider-mark-text {
      font-size: 16px;

      color: #999;
    }

    .rc-slider-handle {
      width: 20px;
      height: 20px;
      margin-top: -7px;

      border: none;
      background-color: #694a38;

      &:active {
        box-shadow: none;
      }
    }

    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
      box-shadow: none;
    }
  }
}

.name {
  margin-bottom: 22px;
}

.email {
  margin-bottom: 22px;
}

.city {
  margin-bottom: 22px;
}

.text {
  margin-bottom: 31px;
}

.policy {
  margin-bottom: 26px;
}

.confirm {
  width: 100%;
  height: 55px;

  font-size: 18px;
  font-weight: 500;

  border-radius: 5px;
  color: #fff;
  background-color: #694a38;

  &:hover {
    background-color: #462d1f;
  }
}

@media (min-width: 1200px) {
  .module {
    padding-left: 200px;
    padding-right: 200px;
  }
}
