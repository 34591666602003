.module {
  position: absolute;

  width: 300px;
  padding: 14px 24px;

  border: 1px solid #efe1cd;
  border-radius: 5px;
  background-color: #fff;
}

.child {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.name {
  font-size: 16px;
  // margin-right: 60px;
}

.select {
  width: 92px;
}

.button {
  width: 100%;
  height: 45px;

  font-size: 16px;
  font-weight: 500;

  border-radius: 5px;
  color: #fff;
  background-color: #694a38;
}
