.module {
  display: flex;
}

.checkbox {
  margin-right: 11px;
}

.text {
  color: rgba(0, 0, 0, 0.4);
}

.button {
  text-decoration: underline;

  color: #000;
  cursor: pointer;
}
