.calculation {
  position: relative;

  background-color: map-get($colors, 'bg');

  &__bg-1 {
    position: absolute;
    left: -50%;
    bottom: 70px;
  }

  &__bg-2 {
    display: none;
  }

  &__content {
    position: relative;

    padding-top: 44px;
    padding-bottom: 58px;
  }

  &__textual {
    margin-bottom: 44px;
  }

  &__title {
    margin-bottom: 22px;

    font-size: 26px;
    font-weight: 700;
    line-height: 1.29;
  }

  &__text {
    max-width: 270px;

    font-size: 16px;
    line-height: 1.5;
  }
}

@media (min-width: 768px) {
  .calculation {
    &__bg-1 {
      display: none;
    }

    &__bg-2 {
      position: absolute;
      top: 0;
      right: -30px;

      display: flex;
      height: 100%;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      padding-top: 58px;
      padding-bottom: 65px;
    }

    &__textual {
      width: 300px;
      margin-bottom: 0;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__quiz {
      width: 370px;
    }
  }
}

@media (min-width: 1200px) {
  .calculation {
    overflow: hidden;

    &__bg-1 {
      left: -150px;
      bottom: -100px;

      display: block;
    }

    &__content {
      padding-top: 83px;
      padding-bottom: 100px;
    }

    &__textual {
      width: 420px;
    }

    &__title {
      font-size: 35px;
    }

    &__quiz {
      margin-right: 160px;
    }
  }
}
