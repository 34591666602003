.footer {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 48px;
    padding-bottom: 27px;

    border-bottom: 1px solid map-get($colors, 'bg');
  }

  &__logo {
    width: 130px;
    margin-bottom: 27px;
  }

  &__nav {
    display: none;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__phone {
    margin-bottom: 13px;

    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
  }

  &__callback {
    height: 45px;
    padding: 0 24px;

    font-size: 16px;

    border: 1px solid map-get($colors, 'main');
    border-radius: 5px;
    color: map-get($colors, 'main');

    &:hover {
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 21px;
    padding-bottom: 24px;
  }

  &__button {
    line-height: 1.5;

    color: #8f8f8f;

    &:not(:last-child) {
      margin-bottom: 13px;
    }
  }
}

@media (min-width: 768px) {
  .footer {
    &__content {
      flex-direction: row;

      padding-top: 7px;
      padding-bottom: 15px;
    }

    &__logo {
      margin-right: auto;
      margin-bottom: 0;
    }

    &__info {
      flex-direction: row;
      align-items: center;
    }

    &__phone {
      margin-right: 15px;
      margin-bottom: 0;
    }

    &__bottom {
      flex-direction: row;
      justify-content: center;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &__button {
      &:not(:last-child) {
        margin-right: 43px;
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 1200px) {
  .footer {
    &__content {
      position: relative;

      justify-content: space-between;
      margin-left: 210px;
      padding-top: 48px;
      padding-bottom: 22px;
    }

    &__logo {
      position: absolute;
      left: -210px;
    }

    &__nav {
      display: flex;

      a {
        margin-right: 50px;

        font-size: 16px;
        font-weight: 700;

        color: rgba(0, 0, 0, 0.7);

        &:hover {
          color: #000;
        }
      }
    }

    &__bottom {
      justify-content: flex-end;
      padding-top: 10px;
      padding-bottom: 30px;
    }
  }
}
