.around {
  background-color: map-get($colors, 'bg');

  &__content {
    padding-top: 33px;
    padding-bottom: 43px;
  }

  &__title {
    margin-bottom: 37px;

    font-size: 30px;
    font-weight: 700;
    line-height: 1.1719;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 37px;
    }
  }

  &__image {
    position: relative;

    margin-bottom: 9px;
    padding-bottom: 68%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: 5px;
    }
  }

  &__name {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.36;
  }
}

@media (min-width: 768px) {
  .around {
    &__content {
      padding-top: 60px;
      padding-bottom: 30px;
    }

    &__title {
      font-size: 40px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
    }

    &__item {
      width: calc(50% - 30px);
      margin: 0 15px;
      margin-bottom: 40px;
    }

    &__image {
      margin-bottom: 15px;
      padding-bottom: 59%;
    }

    &__name {
      font-size: 23px;
    }
  }
}

@media (min-width: 1200px) {
  .around {
    &__content {
      padding-top: 96px;
      padding-bottom: 56px;
    }

    &__item {
      width: calc(33.33333% - 30px);
      margin-bottom: 42px;

      &:not(:last-child) {
        margin-bottom: 42px;
      }
    }

    &__image {
      padding-bottom: 56%;
    }
  }
}
