.module {
  position: relative;

  display: flex;
  height: 55px;

  border-radius: 5px;
  background-color: #fff;

  &::before {
    position: absolute;
    top: 22px;
    right: 19px;

    width: 8px;
    height: 8px;

    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: rotate(45deg);

    content: '';
  }

  &.mini {
    height: 34px;

    &::before {
      top: 12px;
      right: 12px;
    }

    .select {
      padding-left: 14px;
    }
  }
}

.name {
  position: absolute;
  top: 50%;
  left: 24px;

  font-size: 16px;

  color: #999;
  transform: translateY(-50%);
}

.select {
  position: relative;

  width: 100%;
  height: 100%;
  padding-left: calc(100% - 60px);

  font-size: 16px;

  border: 1px solid #efe1cd;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  appearance: none;
}
