.overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: auto;

  background-color: rgba(0, 0, 0, 0.5);

  transition: background 0.3s;
}
