.contacts {
  position: relative;

  #contacts {
    position: absolute;
    top: -66px;
  }

  &__content {
    padding-top: 58px;
  }

  &__title {
    margin-bottom: 13px;

    font-size: 30px;
    font-weight: 700;
    line-height: 1.5;
  }

  &__textual {
    margin-bottom: 42px;
  }

  &__phone {
    display: flex;
    margin-bottom: 19px;

    font-size: 22px;
    font-weight: 500;
    line-height: 1.5;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__icon {
    width: 18px;
    margin-right: 14px;
  }

  &__value {
    font-size: 16px;
    line-height: 1.5;
  }

  &__map {
    position: relative;

    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 80%;
  }

  #map {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
}

@media (min-width: 576px) {
  .contacts {
    &__map {
      margin-left: calc((100vw - 540px) / -2);
      margin-right: calc((100vw - 540px) / -2);
    }
  }
}

@media (min-width: 768px) {
  .contacts {
    #contacts {
      top: -102px;
    }

    &__content {
      position: relative;

      padding-top: 0;
    }

    &__title {
      position: absolute;
      top: 50px;
      left: 0;

      margin-bottom: 0;

      font-size: 40px;
    }

    &__block {
      display: flex;
    }

    &__textual {
      width: 40%;
      margin-bottom: 0;
      padding-top: 140px;
    }

    &__phone {
      margin-bottom: 30px;

      font-size: 26px;
    }

    &__item {
      max-width: 290px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    &__map {
      width: calc(60% + (100vw - 720px) / 2);
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 60%;
      margin-right: calc((100vw - 720px) / -2);
    }
  }
}

@media (min-width: 1200px) {
  .contacts {
    #contacts {
      top: -84px;
    }

    &__title {
      top: 87px;
    }

    &__textual {
      width: 400px;
      padding-top: 204px;
    }

    &__map {
      width: calc((100% - 400px) + (100vw - 1140px) / 2);
      margin-right: calc((100vw - 1140px) / -2);
      padding-bottom: 500px;
    }

    &__phone {
      margin-bottom: 18px;
    }
  }
}
