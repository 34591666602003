.module {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 66px 20px 60px;
}

.icon {
  margin-bottom: 20px;
}

.title {
  margin-bottom: 20px;

  font-size: 26px;
  font-weight: 500;
  line-height: 1.1719;
}

.text {
  font-size: 16px;
  line-height: 1.5419;
  text-align: center;

  color: #999;
}
