.module {
  position: relative;

  display: flex;

  &::before {
    position: absolute;
    top: 22px;
    right: 19px;

    width: 8px;
    height: 8px;

    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: rotate(45deg);

    content: '';
  }
}

.input {
  display: flex;
  width: 100%;
  height: 55px;
  padding: 0 24px;

  font-size: 16px;

  border: 1px solid #efe1cd;
  border-radius: 5px;
  background-color: #fff;
  outline: none;
  cursor: pointer;

  &::placeholder {
    color: #999;
  }
}
