.module {
  position: relative;

  flex: 0 1 16px;
  height: 16px;

  border: 1px solid #d2d2d2;
  border-radius: 1px;
}

.checkbox {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;

  cursor: pointer;
  appearance: none;

  &:checked + .checkMark {
    position: absolute;
    top: 4px;
    left: 3px;

    width: 8px;
    height: 5px;

    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: rotate(-45deg);
  }
}
