.module {
  position: relative;

  width: 400px;
  max-width: 100%;
  margin: 20px auto auto;
  overflow: hidden;

  background-color: #faf8f4;

  &.fadeIn {
    animation: fadeIn 0.3s;
  }

  &.fadeOut {
    animation: fadeOut 0.3s;
  }
}

.bg {
  position: absolute;
  left: 0;
  bottom: 0;

  width: 80%;

  transform: translate(-40%, 40%);
}

.close {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;

  width: 40px;
  height: 40px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 23px;
    height: 2px;

    border-radius: 1px;
    background-color: #000;
    transform: translate(-50%, -50%) rotate(45deg);

    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 23px;
    height: 2px;

    border-radius: 1px;
    background-color: #000;
    transform: translate(-50%, -50%) rotate(-45deg);

    content: '';
  }
}

@media (min-width: 1200px) {
  .module {
    width: 770px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-200px);
  }
}
