.question {
  position: relative;

  background-color: map-get($colors, 'bg');

  &__bg {
    position: absolute;
    top: 0;
    right: -50%;

    max-width: none;
    height: 100%;
  }

  &__content {
    position: relative;

    padding-top: 48px;
    padding-bottom: 49px;
  }

  &__title {
    margin-bottom: 30px;

    font-size: 30px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
  }

  &__text {
    margin-bottom: 39px;

    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }

  &__button {
    display: block;
    height: 55px;
    margin: 0 auto;
    padding: 0 35px;

    font-size: 16px;
    font-weight: 500;

    border-radius: 5px;
    color: #fff;
    background-color: map-get($colors, 'second');

    &:active,
    &:hover {
      background-color: map-get($colors, 'second-dark');
    }
  }
}

@media (min-width: 768px) {
  .question {
    &__bg {
      right: 0;
    }

    &__content {
      padding-top: 56px;
      padding-bottom: 78px;
    }

    &__title {
      margin-bottom: 26px;

      font-size: 40px;
    }

    &__text {
      max-width: 330px;

      margin: 0 auto;
      margin-bottom: 23px;
    }
  }
}

@media (min-width: 1200px) {
  .question {
    &__content {
      padding-top: 86px;
      padding-bottom: 101px;
    }
  }
}
