.module {
  position: relative;

  padding: 49px 20px 57px;
}

.title {
  margin-bottom: 32px;

  font-size: 26px;
  font-weight: 500;
  line-height: 1.1719;
  text-align: center;
}

.arrival {
  margin-bottom: 22px;
}

.departure {
  margin-bottom: 22px;
}

.adults {
  margin-bottom: 22px;
}

.children {
  margin-bottom: 22px;
}

.name {
  margin-top: 22px;
  margin-bottom: 22px;
}

.phone {
  margin-bottom: 33px;
}

.policy {
  margin-bottom: 26px;
}

.confirm {
  width: 100%;
  height: 55px;

  font-size: 18px;
  font-weight: 500;

  border-radius: 5px;
  color: #fff;
  background-color: #694a38;

  &:hover {
    background-color: #462d1f;
  }
}

@media (min-width: 1200px) {
  .module {
    padding-left: 200px;
    padding-right: 200px;
  }
}
