// Font >>>
$font-family: 'Roboto', sans-serif;
$font-size: 14px;
$font-weight: 400;
// Font <<<

// Colors >>>
$colors: (
  'main': #694a38,
  'main-dark': #462d1f,
  'second': #f0ba59,
  'second-dark': #db9e2b,
  'bg': #faf8f4,
);
// Colors <<<

// GRID >>>
$columns: 12;
$breakpoints: (
  '': 0,
  'sm': 576,
  'md': 768,
  'lg': 992,
  'xl': 1200,
);
$gutter-values: (
  '': 30,
  'sm': 30,
  'md': 30,
  'lg': 30,
  'xl': 30,
);
$container-values: (
  'sm': 540,
  'md': 720,
  'lg': 960,
  'xl': 1140,
);
// GRID <<<
