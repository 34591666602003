.rooms {
  $p: &;

  position: relative;

  &--active {
    #{$p}__item--hidden {
      height: auto;
      margin-bottom: 30px;

      visibility: visible;
    }

    #{$p}__more {
      display: none;
    }
  }

  #rooms {
    position: absolute;
    top: -66px;
  }

  &__content {
    padding-top: 50px;
    padding-bottom: 67px;
  }

  &__title {
    margin-bottom: 34px;

    font-size: 30px;
    font-weight: 700;
    line-height: 1.1719;
    text-align: center;
  }

  &__list {
    margin-bottom: 33px;
  }

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    &--hidden {
      height: 0;
      margin-bottom: 0;
      overflow: hidden;

      visibility: hidden;
    }
  }

  &__discount {
    position: absolute;
    z-index: 2;
    top: 7px;
    left: 9px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 59px;
    height: 59px;

    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.1px;

    border-radius: 50%;
    color: #fff;
    background-color: map-get($colors, 'second');
  }

  &__slider {
    position: relative;
  }

  &__slide {
    position: relative;

    display: flex;
    padding-bottom: 69%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;

      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;

    width: 40px;
    height: 40px;

    transform: translateY(-50%);

    &::before {
      position: absolute;
      top: 15px;
      left: 10px;

      width: 10px;
      height: 10px;

      border-left: 2px solid rgba(255, 255, 255, 0.7);
      border-bottom: 2px solid rgba(255, 255, 255, 0.7);
      border-bottom-left-radius: 1px;
      transform: rotate(45deg);

      content: '';
    }

    &::after {
      position: absolute;
      top: 19px;
      left: 10px;

      width: 20px;
      height: 2px;

      background-color: rgba(255, 255, 255, 0.7);

      content: '';
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;

    width: 40px;
    height: 40px;

    transform: translateY(-50%);

    &::before {
      position: absolute;
      top: 15px;
      right: 10px;

      width: 10px;
      height: 10px;

      border-top: 2px solid rgba(255, 255, 255, 0.7);
      border-right: 2px solid rgba(255, 255, 255, 0.7);
      border-top-right-radius: 1px;
      transform: rotate(45deg);

      content: '';
    }

    &::after {
      position: absolute;
      top: 19px;
      right: 10px;

      width: 20px;
      height: 2px;

      background-color: rgba(255, 255, 255, 0.7);

      content: '';
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 11px;

    .swiper-pagination-bullet {
      width: 7px;
      height: 7px;

      border: 1px solid map-get($colors, 'second');
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &-active {
        width: 10px;
        height: 10px;

        background-color: map-get($colors, 'second');
      }
    }
  }

  &__body {
    padding: 12px 15px 16px;

    border: 1px solid map-get($colors, 'second');
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__name {
    margin-bottom: 8px;

    font-size: 22px;
    font-weight: 500;
    line-height: 1.1;
  }

  &__prices {
    margin-bottom: 15px;

    font-size: 16px;
    line-height: 1.2;

    del {
      margin-right: 17px;

      text-decoration: line-through;
    }

    strong {
      font-weight: 700;

      color: map-get($colors, 'second');
    }
  }

  &__seats {
    display: flex;
    margin-bottom: 10px;

    line-height: 1.2;

    strong {
      margin-right: 6px;

      font-weight: 700;
    }
  }

  &__view {
    display: flex;
    margin-bottom: 15px;

    line-height: 1.2;

    strong {
      margin-right: 6px;

      font-weight: 700;
    }
  }

  &__properties {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    span {
      position: relative;

      display: flex;
      padding-left: 10px;

      line-height: 1.2;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &::before {
        position: absolute;
        top: 6px;
        left: 0;

        width: 4px;
        height: 4px;
        border-radius: 50%;

        background-color: map-get($colors, 'main');

        content: '';
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
  }

  &__booking {
    height: 55px;
    margin-bottom: 20px;

    font-size: 16px;
    font-weight: 500;

    border-radius: 5px;
    color: #fff;
    background-color: map-get($colors, 'main');

    &:active,
    &:hover {
      background-color: map-get($colors, 'main-dark');
    }
  }

  &__calculate {
    height: 55px;

    font-size: 16px;
    font-weight: 500;

    border: 1px solid map-get($colors, 'main');
    border-radius: 5px;
    color: map-get($colors, 'main');

    &:active,
    &:hover {
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }

  &__more {
    position: relative;

    display: block;
    margin: 0 auto;
    margin-bottom: 33px;
    padding-right: 17px;

    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;

    color: map-get($colors, 'second');

    &::before {
      position: absolute;
      top: 3px;
      right: 2px;

      width: 8px;
      height: 8px;

      border-left: 1px solid map-get($colors, 'second');
      border-bottom: 1px solid map-get($colors, 'second');
      transform: rotate(-45deg);

      content: '';
    }
  }

  &__appendix {
    padding: 12px 32px;

    font-size: 22px;
    font-weight: 500;
    line-height: 1.3869;
    text-align: center;

    background-color: map-get($colors, 'bg');
  }
}

@media (min-width: 768px) {
  .rooms {
    #rooms {
      top: -102px;
    }

    &__content {
      padding-top: 15px;
      padding-bottom: 75px;
    }

    &__title {
      margin-bottom: 51px;

      font-size: 40px;
      text-align: left;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      margin-bottom: 11px;
    }

    &__item {
      width: calc(50% - 30px);
      margin: 0 15px;
      margin-bottom: 30px;

      &--hidden {
        margin-bottom: 0;
      }
    }

    &__slide {
      padding-bottom: 74%;
    }

    &__body {
      position: relative;

      padding: 18px 17px 21px;
    }

    &__pagination {
      position: absolute;
      z-index: 10;
      left: 50%;
      bottom: calc(100% + 10px);

      margin-bottom: 0;
      transform: translateX(-50%);
    }

    &__name {
      font-size: 26px;
    }

    &__prices {
      font-size: 21px;

      del {
        margin-right: 10px;
      }
    }

    &__seats {
      margin-bottom: 14px;
    }

    &__view {
      margin-bottom: 23px;
    }

    &__properties {
      margin-bottom: 24px;
    }

    &__buttons {
      flex-direction: row;
    }

    &__booking {
      flex-grow: 1;
      margin-right: 12px;
      margin-bottom: 0;
    }

    &__calculate {
      flex-grow: 1;
    }

    &__more {
      margin-bottom: 71px;
    }

    &__appendix {
      padding-top: 25px;
      padding-bottom: 25px;

      font-size: 26px;
    }
  }
}

@media (min-width: 1200px) {
  .rooms {
    #rooms {
      top: -84px;
    }

    &__content {
      padding-top: 75px;
      padding-bottom: 66px;
    }

    &__title {
      margin-bottom: 34px;
    }

    &__item {
      width: calc(33.33333% - 30px);
    }

    &__slide {
      padding-bottom: 69%;
    }

    &__more {
      margin-bottom: 42px;
    }
  }
}
