.module {
  display: flex;
  width: 100%;
  height: 150px;
  padding: 18px 24px;

  font-size: 16px;

  border: 1px solid #efe1cd;
  border-radius: 5px;
  outline: none;

  &::placeholder {
    color: #999;
  }
}
