.screen {
  position: relative;

  overflow: hidden;

  background-color: map-get($colors, 'bg');

  &__bg {
    position: absolute;
    left: -280px;
    bottom: -240px;

    max-width: 670px;
  }

  &__content {
    position: relative;

    padding-top: 66px;
    padding-bottom: 47px;
  }

  &__image {
    position: relative;

    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 23px;
    padding-bottom: 53%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    margin-bottom: 20px;

    font-size: 38px;
    font-weight: 300;
    line-height: 1.1369;
    text-align: center;

    strong {
      font-weight: 700;
    }
  }

  &__offer {
    max-width: 230px;
    margin: 0 auto;
    margin-bottom: 36px;

    font-size: 16px;
    line-height: 1.5719;
    text-align: center;

    strong {
      font-weight: 700;

      color: map-get($colors, 'second');
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__booking {
    width: 191px;
    height: 55px;
    margin-bottom: 20px;

    font-size: 18px;
    font-weight: 500;

    border-radius: 5px;
    color: #fff;
    background-color: map-get($colors, 'main');

    &:active,
    &:hover {
      background-color: map-get($colors, 'main-dark');
    }
  }

  &__calculate {
    width: 191px;
    height: 55px;

    font-size: 18px;
    font-weight: 500;

    border: 1px solid map-get($colors, 'main');
    border-radius: 5px;
    color: map-get($colors, 'main');

    &:active,
    &:hover {
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }
}

@media (min-width: 576px) {
  .screen {
    &__image {
      margin-left: calc((100vw - 540px) / -2);
      margin-right: calc((100vw - 540px) / -2);
    }
  }
}

@media (min-width: 768px) {
  .screen {
    &__content {
      padding-top: 102px;
      padding-bottom: 39px;
    }

    &__image {
      margin-left: calc((100vw - 720px) / -2);
      margin-right: calc((100vw - 720px) / -2);
      margin-bottom: 38px;
      padding-bottom: 38%;
    }

    &__title {
      margin-bottom: 27px;

      font-size: 64px;
      text-align: left;
    }

    &__offer {
      max-width: none;
      margin: 0;
      margin-bottom: 27px;

      font-size: 20px;
      text-align: left;
    }

    &__buttons {
      flex-direction: row;
    }

    &__booking {
      margin-bottom: 0;
      margin-right: 19px;
    }
  }
}

@media (min-width: 1200px) {
  .screen {
    &__content {
      display: flex;
      padding-top: 84px;
      padding-bottom: 0;
    }

    &__image {
      order: 1;
      width: calc((100% - 540px) + (100vw - 1140px) / 2);
      height: 614px;
      margin-left: 0;
      margin-right: calc((100vw - 1140px) / -2);
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &__block {
      width: 540px;
      padding-top: 81px;
    }

    &__title {
      margin-bottom: 15px;
    }

    &__offer {
      margin-bottom: 43px;
    }
  }
}
