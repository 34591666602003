.module {
  position: fixed;
  z-index: 100;
  left: 20px;
  right: 20px;
  bottom: 40px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 40px 18px 20px;

  // border: 1px solid #f0ba59;
  border-radius: 5px;
  background-color: #faf8f4;
}

.close {
  position: absolute;
  top: 0;
  right: 0;

  width: 40px;
  height: 40px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 23px;
    height: 2px;

    border-radius: 1px;
    background-color: #000;
    transform: translate(-50%, -50%) rotate(45deg);

    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 23px;
    height: 2px;

    border-radius: 1px;
    background-color: #000;
    transform: translate(-50%, -50%) rotate(-45deg);

    content: '';
  }
}

.text {
  margin-bottom: 22px;

  line-height: 1.5;
}

.detail {
  text-decoration: underline;
}

.confirm {
  flex-shrink: 0;
  height: 55px;
  padding: 0 28px;

  font-size: 16px;
  font-weight: 500;

  border-radius: 5px;
  color: #fff;
  background-color: #694a38;

  &:hover {
    background-color: #462d1f;
  }
}

@media (min-width: 768px) {
  .module {
    left: 50%;
    right: auto;

    width: 720px;

    transform: translateX(-50%);
  }
}

@media (min-width: 1200px) {
  .module {
    flex-direction: row;
    align-items: center;
    width: 770px;
    padding-left: 40px;
    padding-right: 20px;
  }

  .close {
    left: 0;
    right: auto;
  }

  .text {
    margin-bottom: 0;
  }
}
