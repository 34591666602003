.header {
  $p: &;

  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  width: 100%;

  background-color: map-get($colors, 'bg');

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 66px;
  }

  &__logo {
    img {
      height: 45px;
    }
  }

  // &__block {
  // }

  &__nav {
    display: none;
  }

  &__info {
    display: none;
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 48px;
    height: 48px;

    // background-color: pink;

    &::before {
      width: 32px;
      height: 2px;

      background-color: #000;

      content: '';
    }

    span {
      width: 22px;
      height: 2px;
      margin: 5px 0;

      background-color: #000;
    }

    &::after {
      width: 32px;
      height: 2px;

      background-color: #000;

      content: '';
    }
  }

  &--open {
    #{$p}__block {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 230px;
      padding-bottom: 25px;

      background-color: #fff;
    }

    #{$p}__button {
      align-items: center;
      margin-bottom: 30px;

      &::before {
        width: 22px;

        border-radius: 1px;
        transform: rotate(45deg);
      }

      span {
        display: none;
      }

      &::after {
        position: relative;
        top: -2px;

        width: 22px;

        border-radius: 1px;
        transform: rotate(-45deg);
      }
    }

    #{$p}__nav {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 14px;
      padding-bottom: 47px;

      border-bottom: 1px solid #faf8f4;

      a {
        height: 24px;

        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;

        color: rgba(0, 0, 0, 0.7);

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &:active {
          border-bottom: 1px solid #000;
          color: #000;
        }
      }
    }

    #{$p}__info {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
    }

    #{$p}__phone {
      margin-bottom: 12px;

      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
    }

    #{$p}__callback {
      height: 45px;
      padding: 0 11px;

      font-size: 16px;
      font-weight: 500;

      border: 1px solid map-get($colors, 'main');
      border-radius: 5px;
      color: map-get($colors, 'main');
    }
  }
}

@media (min-width: 768px) {
  .header {
    $p: &;

    &__content {
      height: 102px;
    }

    &__logo {
      img {
        height: 50px;
      }
    }

    &__block {
      display: flex;
      align-items: center;
    }

    &__button {
      order: 1;
    }

    &__info {
      display: flex;
      align-items: center;
      margin-right: 40px;
    }

    &__phone {
      margin-right: 20px;

      font-size: 20px;
      font-weight: 700;
    }

    &__callback {
      height: 45px;
      padding: 0 24px;

      font-size: 16px;
      font-weight: 500;

      border: 1px solid map-get($colors, 'main');
      border-radius: 5px;
      color: map-get($colors, 'main');

      &:hover {
        color: #fff;
        background-color: map-get($colors, 'main');
      }
    }

    &--open {
      #{$p}__content {
        position: relative;
      }

      #{$p}__block {
        position: static;

        flex-direction: row;
        align-items: center;
        width: auto;
        padding-bottom: 0;

        background-color: transparent;
      }

      #{$p}__button {
        position: relative;
        z-index: 1;
        right: 10px;

        margin-bottom: 0;
      }

      #{$p}__nav {
        position: absolute;
        top: 20px;
        right: 0;

        width: 267px;
        margin: 0;
        padding-top: 77px;
        padding-right: 28px;
        padding-bottom: 42px;

        background-color: #fff;
      }

      #{$p}__info {
        flex-direction: row;
        align-items: center;
        margin-right: 40px;
      }

      #{$p}__phone {
        margin-bottom: 0;

        font-size: 20px;
      }

      #{$p}__callback {
        padding: 0 24px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .header {
    &__content {
      height: 84px;
    }

    &__block {
      flex-grow: 1;
    }

    &__button {
      display: none;
    }

    &__nav {
      display: flex;
      justify-content: center;
      flex-grow: 1;

      a {
        font-size: 16px;
        font-weight: 700;

        color: rgba(0, 0, 0, 0.7);

        &:not(:last-child) {
          margin-right: 52px;
        }

        &:hover {
          border-bottom: 1px solid #000;
          color: #000;
        }
      }
    }
  }
}
