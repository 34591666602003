.title {
  margin-bottom: 5px;

  font-size: 26px;
  font-weight: 500;
  line-height: 1.2;
}

.steps {
  margin-bottom: 37px;

  font-size: 16px;

  color: #f0ba59;
}

.slider {
  margin-bottom: 22px;
  overflow: hidden;
}

.wrapper {
  display: flex;

  transition: 0.2s;

  & > * {
    flex: 1 0 100%;
  }
}

.buttons {
  display: flex;
}

.next {
  flex-grow: 1;
  height: 55px;

  font-size: 18px;
  font-weight: 500;

  border-radius: 5px;
  color: #fff;
  background-color: #694a38;

  &:hover {
    background-color: #462d1f;
  }
}

.prev {
  position: relative;

  width: 55px;
  height: 55px;
  margin-right: 21px;

  border: 2px solid #694a38;
  border-radius: 5px;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 23px;
    height: 2px;

    background-color: #694a38;
    transform: translate(-50%, -50%);

    content: '';
  }

  &::after {
    position: absolute;
    top: 21px;
    left: 14px;

    width: 10px;
    height: 10px;

    border-left: 2px solid #694a38;
    border-bottom: 2px solid #694a38;
    border-bottom-left-radius: 1px;
    transform: rotate(45deg);

    content: '';
  }

  &:hover {
    background-color: #694a38;

    &::before {
      background-color: #fff;
    }

    &::after {
      border-color: #fff;
    }
  }
}

.confirm {
  width: 100%;
  height: 55px;

  font-size: 18px;
  font-weight: 500;

  border-radius: 5px;
  color: #fff;
  background-color: #694a38;

  &:hover {
    background-color: #462d1f;
  }
}
