.module {
  position: relative;

  display: flex;
  flex-direction: column;
  padding: 49px 20px 57px;
}

.name {
  margin-bottom: 6px;

  font-size: 26px;
  font-weight: 500;
  line-height: 1.2;
}

.grade {
  margin-bottom: 18px;

  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;

  color: #f0ba59;
}

.text {
  font-size: 16px;
  line-height: 1.5719;
}

@media (min-width: 1200px) {
  .module {
    padding-left: 100px;
    padding-right: 100px;
  }
}
