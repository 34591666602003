.about {
  $p: &;

  position: relative;

  #about {
    position: absolute;
    top: -66px;
  }

  &__content {
    padding-top: 36px;
    padding-bottom: 45px;
  }

  &__title {
    margin-bottom: 13px;

    font-size: 30px;
    font-weight: 700;
    line-height: 1.1719;
  }

  &__main {
    margin-bottom: 20px;

    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
  }

  &__block {
    margin-bottom: 20px;

    &--active {
      #{$p}__two {
        display: block;
      }

      #{$p}__detail {
        display: none;
      }
    }
  }

  &__one {
    margin-bottom: 20px;

    font-size: 16px;
    line-height: 1.5;
  }

  &__two {
    display: none;

    font-size: 16px;
    line-height: 1.5;
  }

  &__detail {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    text-decoration: underline;

    color: map-get($colors, 'second');
  }

  &__slider {
    position: relative;

    margin-bottom: 21px;
  }

  &__slide {
    position: relative;

    display: flex;
    padding-bottom: 64%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: 5px;
    }
  }

  &__prev {
    position: absolute;
    top: 50%;
    left: -20px;

    width: 20px;
    height: 40px;

    transform: translateY(-50%);

    &::before {
      position: absolute;
      top: 15px;
      left: 3px;

      width: 10px;
      height: 10px;

      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      border-bottom-left-radius: 1px;
      transform: rotate(45deg);

      content: '';
    }

    &::after {
      position: absolute;
      top: 19px;
      left: 3px;

      width: 15px;
      height: 2px;

      background-color: #000;

      content: '';
    }
  }

  &__next {
    position: absolute;
    top: 50%;
    right: -20px;

    width: 20px;
    height: 40px;

    transform: translateY(-50%);

    &::before {
      position: absolute;
      top: 15px;
      right: 3px;

      width: 10px;
      height: 10px;

      border-top: 2px solid #000;
      border-right: 2px solid #000;
      border-top-right-radius: 1px;
      transform: rotate(45deg);

      content: '';
    }

    &::after {
      position: absolute;
      top: 19px;
      right: 3px;

      width: 15px;
      height: 2px;

      background-color: #000;

      content: '';
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;

      border: 1px solid map-get($colors, 'main');
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 12px;
      }

      &-active {
        width: 14px;
        height: 14px;

        background-color: map-get($colors, 'main');
      }
    }
  }
}

@media (min-width: 768px) {
  .about {
    #about {
      top: -102px;
    }

    &__content {
      padding-top: 63px;
      padding-bottom: 74px;
    }

    &__title {
      margin-bottom: 27px;

      font-size: 40px;
    }

    &__main {
      margin-bottom: 27px;

      font-size: 25px;
      line-height: 1.5719;
    }

    &__block {
      margin-bottom: 43px;
    }

    &__one {
      margin-bottom: 27px;

      line-height: 1.5719;
    }

    &__two {
      line-height: 1.5719;
    }

    &__slider {
      margin-bottom: 27px;
    }

    &__slide {
      padding-bottom: 74%;
    }
  }
}

@media (min-width: 1200px) {
  .about {
    #about {
      top: -84px;
    }

    &__content {
      padding-top: 96px;
      padding-bottom: 101px;
    }

    &__main {
      margin-bottom: 48px;
    }

    &__block {
      display: flex;
      margin-bottom: 60px;
    }

    &__one {
      width: calc(50% - 15px);
      margin-right: 15px;
      margin-bottom: 0;
    }

    &__two {
      display: block;
      width: calc(50% - 15px);
      margin-left: 15px;
    }

    &__detail {
      display: none;
    }

    &__slider {
      margin-bottom: 37px;
    }

    &__slide {
      padding-bottom: 64%;
    }

    &__prev {
      left: -60px;

      width: 40px;

      &::before {
        left: 1px;
      }

      &::after {
        left: 1px;

        width: 38px;
      }
    }

    &__next {
      right: -60px;

      width: 40px;

      &::before {
        right: 1px;
      }

      &::after {
        right: 1px;

        width: 38px;
      }
    }
  }
}
