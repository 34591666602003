.module {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  margin-top: 22px;
  padding-left: 24px;
  padding-right: 40px;

  border: 1px solid #efe1cd;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;

  &::before {
    position: absolute;
    top: 22px;
    right: 19px;

    width: 8px;
    height: 8px;

    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: rotate(45deg);

    content: '';
  }
}

.label {
  font-size: 16px;

  color: #999;
}

.values {
  font-size: 16px;
}
