.module {
  position: relative;

  padding: 49px 20px 57px;
}

.title {
  margin-bottom: 32px;

  font-size: 26px;
  font-weight: 500;
  line-height: 1.1719;
  text-align: center;
}

.content {
  font-size: 16px;
  line-height: 1.5;

  p:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media (min-width: 1200px) {
  .module {
    padding-left: 100px;
    padding-right: 100px;
  }
}
